<template>
  <environment-template
    :only-greasy-in-summer="onlyGreasyInSummer"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import EnvironmentTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-oiliness/environment/EnvironmentTemplate';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('onlyGreasyInSummer')];

export default {
  name: 'Environment',
  components: {
    EnvironmentTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    onlyGreasyInSummer() {
      this.showNextStep();
    }
  }
};
</script>
